import { SecuredPaymentInfoVisitor } from "src/features/paymentGateways/types";
import { getToastPaymentSessionData } from "../selectors";
import { getSelectedPaymentMethod } from "src/features/payment/selectors";
import { isCardPaymentMethod, isDevicePaymentMethod, paymentOptions, PaymentType } from "src/common/payment";
import { createToastPaymentMethod } from "../actions/createToastPaymentMethod";

export const visitToastSecuredPaymentInfo: SecuredPaymentInfoVisitor = async (_, getState, securedPayment) => {
    const state = getState();

    const selectedPaymentMethod = getSelectedPaymentMethod(state);

    if (!selectedPaymentMethod) return;

    if (!isCardPaymentMethod(selectedPaymentMethod)) {
        const { intentId: paymentId } = getToastPaymentSessionData(state);

        securedPayment.posPaymentInfo = {
            paymentId,
        };
    }

    if (selectedPaymentMethod.paymentType === PaymentType.ADDEDCARD) {
        const { productId, remember } = selectedPaymentMethod;

        securedPayment.addedCardPaymentInfo = {
            productId,
            remember,
        };
    }

    if (isDevicePaymentMethod(selectedPaymentMethod)) {
        const { paymentMethodId } = await createToastPaymentMethod([{ label: paymentOptions.label, amount: 0 }]);

        securedPayment.token = paymentMethodId;

        securedPayment.devicePaymentInfo = {
            originalToken: selectedPaymentMethod.token,
        };
    }
};
