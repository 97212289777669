import { applePay, googlePay } from "../experience";

export type PaymentGateway = "Braintree" | "Tyro" | "GroupTab" | "Stripe" | "Spreedly" | "Pos";

type PaymentMethodBase = {
    paymentGateway: PaymentGateway;
    currency: string;
    locale: string;
    countryCode: string;
    visibility?: PaymentMethodVisibility;
};

export enum PaymentMethodVisibility {
    None = 0,
    Profile = 1,
    Payment = 2,
}

type AvailablePaymentMethod = PaymentMethodBase & {
    displayName: string;
    token: string;
};

type FeeIncurringPaymentMethod = {
    productId: string;
    processingFee: number;
    processingFeeBaseAmount: number;
};

type DevicePaymentMethodBase = AvailablePaymentMethod & FeeIncurringPaymentMethod;

export type ApplePayPaymentMethod = DevicePaymentMethodBase & {
    paymentType: PaymentType.APPLEPAY;
    applePayMerchantId: string;
};

export type GooglePayPaymentMethod = DevicePaymentMethodBase &
    VerifiablePaymentMethod & {
        paymentType: PaymentType.GOOGLEPAY;
    };

export type PayPalCheckoutPaymentMethod = DevicePaymentMethodBase & {
    paymentType: PaymentType.PAYPALCHECKOUT;
    payPalClientId: string;
};

export type DevicePaymentMethod = ApplePayPaymentMethod | GooglePayPaymentMethod | PayPalCheckoutPaymentMethod;

export type VerifiablePaymentMethod = {
    verificationMinAmount?: number | null;
};

type VaultedPaymentMethodBase = AvailablePaymentMethod & FeeIncurringPaymentMethod;

type CardPaymentMethodBase = VaultedPaymentMethodBase &
    VerifiablePaymentMethod & {
        maskedNumber: string;
        expirationDate: string;
    };

export type CardPaymentMethod = CardPaymentMethodBase & {
    paymentType: PaymentType.CARD;
    cvv?: string;
};

export type GroupTabPaymentMethod = VaultedPaymentMethodBase & {
    paymentType: PaymentType.GROUPTAB;
};

export type PosPaymentMethod = VaultedPaymentMethodBase & {
    paymentType: PaymentType.POS;
};

export type VaultedPaymentMethod = CardPaymentMethod | GroupTabPaymentMethod | PosPaymentMethod;

export type AddedCardPaymentMethod = CardPaymentMethodBase & {
    paymentType: PaymentType.ADDEDCARD;
    remember: boolean;
};

export type NewCardPaymentMethod = PaymentMethodBase &
    VerifiablePaymentMethod & {
        paymentType: PaymentType.NEWCARD;
        cardTypeVerificationMinAmounts?: { [cardType: string]: number } | null;
    };

export type SelectablePaymentMethod = DevicePaymentMethod | VaultedPaymentMethod | AddedCardPaymentMethod;

export type PaymentMethod = SelectablePaymentMethod | NewCardPaymentMethod;

export enum PaymentType {
    CARD = 0,
    PAYPAL = 1,
    GOOGLEPAY = 2,
    APPLEPAY = 3,
    PAYPALCHECKOUT = 4,
    ADDEDCARD = 5,
    NEWCARD = 6,
    GROUPTAB = 7,
    // MEMBERSHIP = 8,
    POS = 9,
}

export const isDigitalWalletPaymentMethod = (paymentMethod: PaymentMethod) =>
    paymentMethod.paymentType === PaymentType.APPLEPAY || paymentMethod.paymentType === PaymentType.GOOGLEPAY;

export const isDevicePaymentMethod = (paymentMethod: PaymentMethod) =>
    isDigitalWalletPaymentMethod(paymentMethod) || paymentMethod.paymentType === PaymentType.PAYPALCHECKOUT;

export const isCardPaymentMethod = (paymentMethod: PaymentMethod) => paymentMethod.paymentType === PaymentType.CARD;

export const isRemovablePaymentMethod = (paymentMethod: PaymentMethod) =>
    isCardPaymentMethod(paymentMethod) || paymentMethod.paymentType === PaymentType.ADDEDCARD;

export const isDisplayedForVaultedPaymentMethodSelection = (paymentMethod: PaymentMethod) =>
    isVisibleForPayment(paymentMethod) &&
    (isRemovablePaymentMethod(paymentMethod) || paymentMethod.paymentType === PaymentType.NEWCARD);

export const isDisplayedForAllVaultedPaymentMethodSelection = (paymentMethod: PaymentMethod) =>
    isVisibleForPayment(paymentMethod) &&
    (isDisplayedForVaultedPaymentMethodSelection(paymentMethod) ||
        (paymentMethod.paymentType === PaymentType.APPLEPAY && applePay.canVaultPaymentMethod(paymentMethod)) ||
        (paymentMethod.paymentType === PaymentType.GOOGLEPAY && googlePay.canVaultPaymentMethod(paymentMethod)));

export const isNewCardPaymentMethod = (paymentMethod: PaymentMethod) =>
    paymentMethod.paymentType === PaymentType.NEWCARD;

export const isSelectablePaymentMethod = (paymentMethod: PaymentMethod) => !isNewCardPaymentMethod(paymentMethod);

export const isLocationPaymentMethod = (paymentMethod: PaymentMethod) =>
    paymentMethod.paymentType === PaymentType.GROUPTAB || paymentMethod.paymentType === PaymentType.POS;

const isVisible = (paymentMethod: PaymentMethod, visibility: PaymentMethodVisibility) =>
    typeof paymentMethod.visibility !== "number" || (paymentMethod.visibility & visibility) === visibility;

export const isVisibleForPayment = (paymentMethod: PaymentMethod) =>
    isVisible(paymentMethod, PaymentMethodVisibility.Payment);

export const isVisibleForProfile = (paymentMethod: PaymentMethod) =>
    isVisible(paymentMethod, PaymentMethodVisibility.Profile);

export interface AuthorizePaymentResult {
    paymentToken: string;
    additionalFraudProtectionData?: string;
    extendedValidation?: boolean;
    validated?: boolean;
}

export type GetAuthorizePaymentResultFunc = () => Promise<AuthorizePaymentResult | null>;

export type UpdateTotalFunc = (total: number) => void;

export interface InitializeDevicePaymentMethodButtonResult {
    authorizePayment: GetAuthorizePaymentResultFunc;
    updateTotal: UpdateTotalFunc;
}
