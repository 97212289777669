import {
    ToastCreatePaymentMethodErrorEvent,
    ToastCreatePaymentMethodResultEvent,
    ToastCreatePaymentMethodResultEventContent,
} from "src/features/paymentGateways/pos/toast/types";

interface PaymentLine {
    label: string;
    amount: number;
}

export const createToastPaymentMethod = (paymentLines?: PaymentLine[]) =>
    new Promise<ToastCreatePaymentMethodResultEventContent>((resolve, reject) => {
        (window as any).Toast.createPaymentMethod(
            (e: ToastCreatePaymentMethodResultEvent) => {
                const token = e.content?.paymentMethodId;
                if (token) return resolve(e.content);
                reject(new Error("Failed to get token"));
            },
            (e: ToastCreatePaymentMethodErrorEvent) => {
                reject(new Error(e.cause?.message ?? "Failed to create payment method"));
            },
            paymentLines
        );
    });
