import React, { useCallback, useEffect, useRef } from "react";
import { Text } from "src/sharedComponents/controls/text/Text";
import { TappableDiv } from "src/sharedComponents/common/tappable";
import { MembershipIcon } from "src/sharedComponents/assets/icons/Icons";
import "./MembershipCallToAction.scss";
import {
    getFeaturedMembershipState,
    getIsManageMembershipsOpen,
    getTotalMembershipCount,
} from "../selectors/getActiveMembershipState";
import { useDispatch, useSelector } from "react-redux";
import { MembershipOAuthSignup } from "./MembershipOAuthSignup";
import { MembershipFormSignup } from "./MembershipFormSignup";
import { getTopPage, SimpleNavHeader } from "src/common/navigation";
import { manageMembershipsActions } from "..";
import { LocationThemeContainer } from "src/features/location/container/LocationThemeContainer";
import { StatusBar } from "src/common/statusBar";
import { campaignsActionCreators } from "src/features/analytics/actions";
import { OrderCampaigns } from "src/features/order/reducers/types";
import { Price } from "src/features/menu/components/Price";
import { getMembershipHasPriceList, getPotentialMemberSavings } from "../selectors/getVenueMembershipState";
import { MembershipAuthMethods } from "../types/MembershipState";
import { getParty } from "src/features/order";
import { ModalContainer } from "src/common/modal";
import { MembershipCallToActionText } from "./MembershipCallToActionText";
import { getLocationName } from "src/features/menu/selectors";
import { getSignInPage } from "src/features/signup/selectors";
import { waitForKeyboardClose } from "src/common/keyboard";
import { useSignupMembership } from "src/features/membership/hooks/useSignupMembership";
import { getConnectedMembershipState } from "../selectors/getConnectedMembershipState";
import { href } from "src/common/experience";

interface Props {
    instanceName: string;
}

export const MembershipCallToAction = ({ instanceName }: Props) => {
    const dispatch = useDispatch();

    const membershipBI = useRef<{ messageType: string; possibleSavings: undefined | string }>({
        messageType: "",
        possibleSavings: undefined,
    });
    const closeTimeoutMSRef = useRef(250);

    const featuredMembership = useSelector(getFeaturedMembershipState);
    const totalMemberships = useSelector(getTotalMembershipCount);
    const membershipHasPriceList = useSelector(getMembershipHasPriceList);
    const possibleSavings = useSelector(getPotentialMemberSavings);
    const party = useSelector(getParty);
    const venueName = useSelector(getLocationName);
    const isSignInOpen = !!useSelector(getSignInPage);
    const isManageMembershipsOpen = useSelector(getIsManageMembershipsOpen);
    const connectedMembership = useSelector(getConnectedMembershipState);

    const [showSignup, setSignupProgram, closeSignup] = useSignupMembership(instanceName);

    const activeOrderItems = party?.activeOrder.items;

    const getCampaignData = useCallback(() => {
        if (!featuredMembership) return null;
        return {
            campaign_name: OrderCampaigns.MEMBERSHIP,
            data: {
                current_page: getTopPage(),
                membership_program_id: featuredMembership.programId,
                "campaign.saving_funds": membershipBI?.current?.possibleSavings,
                "campaign.membership_type": featuredMembership.type,
                "campaign.membership_message_type": membershipBI?.current?.messageType,
            },
        };
    }, [featuredMembership]);

    useEffect(() => {
        const campaignData = getCampaignData();
        if (campaignData) {
            dispatch(campaignsActionCreators.campaignImpression(campaignData));
        }
    }, [dispatch, getCampaignData]);

    useEffect(() => {
        if (showSignup && isSignInOpen) {
            document.body.classList.add("membership-after-signin");
        } else if (!showSignup) {
            document.body.classList.remove("membership-after-signin");
        }
    }, [showSignup, isSignInOpen]);

    useEffect(() => () => document.body.classList.remove("membership-after-signin"), []);

    useEffect(() => {
        if (!isManageMembershipsOpen) return;
        closeTimeoutMSRef.current = 0;
        const timeout = setTimeout(() => dispatch(manageMembershipsActions.setSignupProgramId(undefined)), 250);
        return () => clearTimeout(timeout);
    }, [dispatch, isManageMembershipsOpen]);


    if (featuredMembership){
        const MEMBERSHIP_TYPE_TEXT = {
            POS: {
                textOne: `Get member offers with every order with your ${featuredMembership.programName} membership.`,
                textTwo: "Link account",
            },
            VENUE_DEFAULT: {
                textOne: (
                    <>
                        <Text mode="bold">Get special offers</Text> at {venueName}
                    </>
                ),
                textTwo: "",
            },
            VENUE_NO_SAVINGS: {
                textOne: "Save on future orders!",
            },
            VENUE_HAS_SAVINGS: {
                textOne: (
                    <>
                        Save <Price price={possibleSavings} /> with this order
                    </>
                ),
            },
        };
    
        const getMembershipActionText = () => {
            if (membershipHasPriceList) {
                if (activeOrderItems?.length && featuredMembership.authentication.method === MembershipAuthMethods.OPT_IN) {
                    const textTwo = featuredMembership.authentication.allowRegistration
                        ? "Sign up for our loyalty program"
                        : "Activate membership";
                    if (possibleSavings) {
                        membershipBI.current = {
                            messageType: "SAVINGS",
                            possibleSavings: possibleSavings.toString(),
                        };
                        return {
                            textOne: MEMBERSHIP_TYPE_TEXT.VENUE_HAS_SAVINGS.textOne,
                            textTwo,
                        };
                    } else {
                        membershipBI.current = {
                            messageType: "FUTURE",
                            possibleSavings: "0",
                        };
                        return {
                            textOne: MEMBERSHIP_TYPE_TEXT.VENUE_NO_SAVINGS.textOne,
                            textTwo,
                        };
                    }
                } else {
                    membershipBI.current = {
                        messageType: "SAVINGS",
                        possibleSavings: undefined,
                    };
                    return MEMBERSHIP_TYPE_TEXT.VENUE_DEFAULT;
                }
            }
            membershipBI.current.messageType = featuredMembership.type || "";
            return MEMBERSHIP_TYPE_TEXT.POS;
        };
    
        const onCtaClick = () => {
            const campaignData = getCampaignData();
            if (campaignData) {
                dispatch(campaignsActionCreators.campaignClicked(campaignData));
            }
            setSignupProgram(featuredMembership);
        };
    
        const actionText = getMembershipActionText();
        if (featuredMembership.cta?.benefitText) {
            actionText.textOne = featuredMembership.cta.benefitText;
        }
        if (featuredMembership.cta?.actionText) {
            actionText.textTwo = featuredMembership.cta.actionText;
        }
        
        return (
            <>
                <TappableDiv onClick={onCtaClick} className="membership-cta">
                    <div className="membership-cta__icon">
                        {featuredMembership.cta?.logoUrl ? (
                            <img src={featuredMembership.cta.logoUrl} alt={featuredMembership.programName} />
                        ) : (
                            <MembershipIcon />
                        )}
                    </div>
                    <div className="membership-cta__text">
                        <MembershipCallToActionText {...actionText} />
                    </div>
                </TappableDiv> 
                <ModalContainer
                    isOpen={showSignup}
                    className={{
                        base: "membership-signup-modal slide-in",
                        afterOpen: "membership-signup-modal--after-open slide-in--after-open",
                        beforeClose: "membership-signup-modal--before-close slide-in--before-close",
                    }}
                    overlayClassName="ReactModal__MembershipSignup slide-in-modal--overlay keyboard-unfixed"
                    bodyOpenClassName="SignUpMemberships-modal__Body--open"
                    closeTimeoutMS={closeTimeoutMSRef.current}
                    onAfterClose={() => (closeTimeoutMSRef.current = 250)}
                >
                    <LocationThemeContainer>
                        <StatusBar backgroundColor="#FFFFFF" />
                        <SimpleNavHeader
                            onBack={closeSignup}
                            closeToBack
                            customBack={`membership-signup/${featuredMembership.programId}`}
                            withBorder
                            rightElement={
                                totalMemberships > 1 ? (
                                    <TappableDiv
                                        onClick={async () => {
                                            await waitForKeyboardClose();
                                            dispatch(manageMembershipsActions.showManageMemberships(true));
                                        }}
                                        className="membership-signup__manage"
                                    >
                                        <Text preset="g-14" mode="bold">
                                            Manage memberships
                                        </Text>
                                    </TappableDiv>
                                ) : undefined
                            }
                        />
                        {featuredMembership.authentication.method === "oauth" ? (
                            <MembershipOAuthSignup onStartFlow={closeSignup} />
                        ) : (
                            <MembershipFormSignup />
                        )}
                    </LocationThemeContainer>
                </ModalContainer>
            </>
        );
    }

    if (connectedMembership && connectedMembership.connectedPromotionCta) {
        const { benefitText, actionText, ctaUrl, logoUrl } = connectedMembership.connectedPromotionCta;
        const ctaBody = (
            <>
                <div className="membership-cta__icon">
                    {logoUrl ? <img src={logoUrl} alt={connectedMembership.programName} /> : <MembershipIcon />}
                </div>
                <div className="membership-cta__text">
                    <MembershipCallToActionText textOne={benefitText ?? ""} textTwo={actionText ?? ""} />
                </div>
            </>
        );
        
        if (ctaUrl) {
            return (
                <a {...href.openInNewWindowAttributes(ctaUrl)} className="membership-cta">
                    {ctaBody}
                </a>
            );
        }
        
        return <div className="membership-cta">{ctaBody}</div>;
    }

    return null;
};
