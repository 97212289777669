import { AddCardBehavior, PaymentBehavior } from "src/features/paymentGateways/types";
import { showToastAddCard } from "./actions/showToastAddCard";
import { ToastHostedCheckout } from "./components/ToastHostedCheckout";
import { visitToastPaymentInfo } from "./visitors/visitToastPaymentInfo";
import { visitToastSecuredPaymentInfo } from "./visitors/visitToastSecuredPaymentInfo";

export const addCardBehavior: AddCardBehavior = {
    showAddCard: showToastAddCard,
    addCardFormElement: ToastHostedCheckout,
    showAddCardFormAutomatically: true,
    disableResetAddCardForm: true,
    disableVaultCard: true,
    disableUnmountPaymentMethods: true,
    clearPaymentMethodOnFailure: true,
};

export const paymentBehavior: PaymentBehavior = {
    paymentInfoVisitor: visitToastPaymentInfo,
    securedPaymentInfoVisitor: visitToastSecuredPaymentInfo,
};
