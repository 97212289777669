import { PaymentInfoVisitor } from "src/features/paymentGateways/types";
import { getToastPaymentSessionData } from "../selectors";
import { isCardPaymentMethod } from "../../../../../common/payment";
import { getSelectedPaymentMethod } from "../../../../payment/selectors";

export const visitToastPaymentInfo: PaymentInfoVisitor = async (_, getState, paymentInfo) => {
    const state = getState();

    const selectedPaymentMethod = getSelectedPaymentMethod(state);

    if (!selectedPaymentMethod) return;

    if (!isCardPaymentMethod(selectedPaymentMethod)) {
        const { intentId: paymentId } = getToastPaymentSessionData(state);

        paymentInfo.posPaymentInfo = {
            paymentId,
        };
    }
};
