import { InitializeDevicePaymentMethodButtonFunc } from "src/common/experience/interface";
import { ApplePayPaymentMethod, GooglePayPaymentMethod, paymentOptions } from "src/common/payment";
import { createToastPaymentMethod } from "src/features/paymentGateways/pos/toast/actions/createToastPaymentMethod";

const initializeToastDevicePaymentButton = async (total: number) => async () => {
    const { paymentMethodId } = await createToastPaymentMethod([{ label: paymentOptions.label, amount: total }]);
    return {
        paymentToken: paymentMethodId,
    };
};

export const initializeToastApplePayButton: InitializeDevicePaymentMethodButtonFunc<ApplePayPaymentMethod> = (
    _,
    total
) => initializeToastDevicePaymentButton(total);

export const initializeToastGooglePayButton: InitializeDevicePaymentMethodButtonFunc<GooglePayPaymentMethod> = (
    _,
    total
) => initializeToastDevicePaymentButton(total);
