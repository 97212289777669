export type MembershipState = ConnectedMembershipState | AvailableMembershipState;

export enum MembershipStatus {
    CONNECTED = 0,
    AVAILABLE = 1,
}

export enum MembershipTypes {
    POS = "POS",
    VENUE = "Venue",
    GENERIC = "Generic",
    SPROUT = "Sprout",
}

export enum MembershipAuthMethods {
    OAUTH = "oauth",
    FORM = "form",
    OPT_IN = "optin",
}

export type ConnectedMembershipState = {
    status: MembershipStatus.CONNECTED;
    memberNumber: string;
    programName: string;
    logoUrl?: string;
    programId: string;
    level: string;
    levelPercentDiscount?: number;
    type?: string;
    pointsBalance?: number;
    pointsSpendBalance?: number;
    pointsSpendInFullBalance?: number;
    usePointsSpendBalance?: boolean;
    discountAppliedText?: string;
    connectedPromotionCta?: ConnectedPromotionCta;
};

export type AvailableMembershipState = {
    status: MembershipStatus.AVAILABLE;
    programName: string;
    programId: string;
    logoUrl?: string;
    features?: string[];
    authentication: MembershipAuthentication;
    links?: MembershipLinks;
    type: string;
    potentialLevelId?: string;
    featured: boolean;
    signupPromotion?: MembershipSignupPromotion;
    cta?: MembershipCta;
    dataCollectionStatement?: MembershipDataCollectionStatement;
};

export type MembershipLinks = {
    terms?: string;
    privacy?: string;
};

export type FormConnectFlow = {
    title?: string;
    fields: FormConnectField[];
};

export type FormConnectField = {
    name: string;
    label: string;
    type?: "barcode" | "number";
    placeholder: string;
    help: string;
    required: boolean;
};

export type MembershipAuthentication = {
    requireMemberId: boolean;
    requireProfile: boolean;
    requireEmail: boolean;
    requireVerification: boolean;
} & (OAuthMembershipAuthentication | FormMembershipAuthentication | OptInMembershipAuthentication);

export type OAuthMembershipAuthentication = {
    method: MembershipAuthMethods.OAUTH;
    url: string;
};

export type FormMembershipAuthentication = {
    method: MembershipAuthMethods.FORM;
    flows: {
        connect: FormConnectFlow;
    };
};

export type OptInMembershipAuthentication = {
    method: MembershipAuthMethods.OPT_IN;
    allowRegistration: boolean;
    flows: {
        connect: FormConnectFlow;
    };
};

export type MembershipSignupPromotion = {
    programId?: string;
    programName?: string;
    logoUrl: string | null;
    bannerUrl: string | null;
    title: string;
    ctaText: string;
    ctaUrl: string;
    termsText: string | null;
    termsUrl: string | null;
    termsUrlText: string | null;
    manualTriggerText: string | null;
    benefits: MembershipSignupPromotionBenefit[];
};

export type MembershipSignupPromotionBenefit = {
    title: string;
    text: string;
    iconUrl: string | null;
};

export type MembershipCta = {
    benefitText: string | null;
    actionText: string | null;
    logoUrl: string | null;
};

export type MembershipDataCollectionStatement = {
    statementIntroText: string | null;
    statementText: string;
    statementUrlText: string | null;
    statementUrl: string | null;
    privacyUrlPrefix: string | null;
    privacyUrlText: string | null;
    privacyUrl: string | null;
};

export type ConnectedPromotionCta = {
    benefitText: string | null;
    actionText: string | null;
    logoUrl: string | null;
    ctaUrl: string | null;
}
