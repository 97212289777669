import { createOperation } from "src/features/operations";
import { AddedCardResult, GetAddedCardFunc } from "src/features/payment";
import { SelectablePaymentMethod } from "src/common/payment";

export const showToastAddCardOperation = createOperation<GetAddedCardFunc>("PAYMENT/SHOW_TOAST_ADD_CARD", {
    showSpinner: true,
});
export const getToastAddedCardOperation = createOperation<AddedCardResult | SelectablePaymentMethod | null>(
    "PAYMENT/GET_TOAST_ADDED_CARD",
    {
        showSpinner: true,
    }
);
