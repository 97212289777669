import { PaymentGatewayBehaviors } from "./types";
import {
    addCardBehavior as braintreeAddCardBehavior,
    paymentBehavior as braintreePaymentBehavior,
    payPalBehavior as braintreePayPalBehavior,
    verifyCardBehavior as braintreeVerifyCardBehavior,
} from "./braintree/behaviors";
import {
    addCardBehavior as tyroAddCardBehavior,
    payPalBehavior as tyroPayPalBehavior,
    verifyCardBehavior as tyroVerifyCardBehavior,
} from "./tyro/behaviors";
import { addCardBehavior as stripeAddCardBehavior, paymentBehavior as stripePaymentBehavior } from "./stripe/behaviors";
import { addCardBehavior as spreedlyAddCardBehavior } from "./spreedly/behaviors";
import { PaymentMethod } from "src/common/payment";
import {
    getPosAddCardBehavior,
    getPosPaymentBehavior,
    getPosPaymentMethodVisibility,
    getPosPayPalBehavior,
    getPosVerifyCardBehavior,
} from "./pos/behaviors";
import { PosPaymentMethod } from "./pos/types";

const paymentGatewayBehaviors: PaymentGatewayBehaviors = {
    Braintree: {
        getAddCardBehavior: () => braintreeAddCardBehavior,
        getVerifyCardBehavior: () => braintreeVerifyCardBehavior,
        getPaymentBehavior: () => braintreePaymentBehavior,
        getPayPalBehavior: () => braintreePayPalBehavior,
    },
    Tyro: {
        getAddCardBehavior: () => tyroAddCardBehavior,
        getVerifyCardBehavior: () => tyroVerifyCardBehavior,
        getPayPalBehavior: () => tyroPayPalBehavior,
    },
    Stripe: {
        getAddCardBehavior: () => stripeAddCardBehavior,
        getPaymentBehavior: () => stripePaymentBehavior,
    },
    GroupTab: {},
    Spreedly: {
        getAddCardBehavior: () => spreedlyAddCardBehavior,
    },
    Pos: {
        getAddCardBehavior: (paymentMethod) =>
            getPosAddCardBehavior((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
        getVerifyCardBehavior: (paymentMethod) =>
            getPosVerifyCardBehavior((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
        getPaymentBehavior: (paymentMethod) =>
            getPosPaymentBehavior((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
        getPayPalBehavior: (paymentMethod) =>
            getPosPayPalBehavior((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
        getPaymentMethodVisibility: (paymentMethod) =>
            getPosPaymentMethodVisibility((paymentMethod as unknown as PosPaymentMethod).posType, paymentMethod),
    },
};

export const getAddCardBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getAddCardBehavior?.(paymentMethod);

export const getVerifyCardBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getVerifyCardBehavior?.(paymentMethod);

export const getPaymentBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getPaymentBehavior?.(paymentMethod);

export const getPayPalBehavior = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getPayPalBehavior?.(paymentMethod);

export const getPaymentMethodVisibility = (paymentMethod: PaymentMethod) =>
    paymentGatewayBehaviors[paymentMethod.paymentGateway].getPaymentMethodVisibility?.(paymentMethod);
