import { PosPaymentGatewayBehaviors, PosType } from "./types";
import { addCardBehavior as toastAddCardBehavior, paymentBehavior as toastPaymentBehavior } from "./toast/behaviors";
import { PaymentMethod, PaymentMethodVisibility, PaymentType } from "src/common/payment";

const posPaymentGatewayBehaviors: PosPaymentGatewayBehaviors = {
    toast: {
        getAddCardBehavior: () => toastAddCardBehavior,
        getPaymentBehavior: () => toastPaymentBehavior,
        getPaymentMethodVisibility: (paymentMethod?: PaymentMethod) => {
            switch (paymentMethod?.paymentType) {
                case PaymentType.APPLEPAY:
                case PaymentType.GOOGLEPAY:
                    return PaymentMethodVisibility.None;
                case PaymentType.CARD:
                    return PaymentMethodVisibility.Profile;
                case PaymentType.NEWCARD:
                    return PaymentMethodVisibility.Payment;
                default:
                    return undefined;
            }
        },
    },
};

export const getPosAddCardBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getAddCardBehavior?.(paymentMethod);

export const getPosVerifyCardBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getVerifyCardBehavior?.(paymentMethod);

export const getPosPaymentBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getPaymentBehavior?.(paymentMethod);

export const getPosPayPalBehavior = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getPayPalBehavior?.(paymentMethod);

export const getPosPaymentMethodVisibility = (posType: PosType, paymentMethod?: PaymentMethod) =>
    posPaymentGatewayBehaviors[posType]?.getPaymentMethodVisibility?.(paymentMethod);
